<template>
  <both-colum-page
    :title="$t('functionManage.title')"
    @functionMethod="functionMethod"
  >
    <base-tree
      slot="left"
      ref="baseTree"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.permissionFindTree"
      :defaultExpandedKeys="defaultExpandedKeys"
      :defaultParams="{
        subjectType: 'PC_MANAGE',
      }"
      v-model="treeRow"
    >
      <template v-slot:default="{ data }">
        {{ $t(`functionEnum.${data.permissionEnum}`) }}
      </template>
    </base-tree>
    <table-list
      :isDefine="true"
      :defaultContent="content"
      :columns="columns"
      v-model="tableRow"
    >
      <!-- 名称枚举-->
      <template v-slot:permissionEnum="{ row }">
        {{ $t(`functionEnum.${row.permissionEnum}`) }}
      </template>
      <!-- 功能类型 -->
      <template v-slot:permissionType="{ row }">
        {{ permissionTypeObj[row.permissionType] }}
      </template>
      <!-- 是否隐藏右上角权限按钮 -->
      <template v-slot:shouldBeBasic="{ row }">
        {{ yesOrNoObj[row.shouldBeBasic] }}
      </template>
      <!-- 放行接口 -->
      <template v-slot:apiSet="{ row }">
        {{ getApiSet(row.apiSet) }}
      </template>
      <!-- 图标 -->
      <template v-slot:icon="{ row }">
        <template v-if="row.icon">
          <i :class="`iconfont ${row.icon}`" /> / {{ row.icon }}
        </template>
      </template>
      <!-- 排序 -->
      <template v-slot:sort="{ index }">
        <el-button
          type="text"
          v-if="index"
          @click="permissionUpdateSort(index, 'up')"
        >
          <!-- lang:上移 -->
          {{ $t('functionManage.moveUp') }}
        </el-button>
        <el-button
          type="text"
          v-if="index !== content.length - 1"
          @click="permissionUpdateSort(index, 'down')"
        >
          <!-- lang:下移 -->
          {{ $t('functionManage.moveDown') }}
        </el-button>
      </template>
    </table-list>
    <detail
      v-if="showDetail"
      :treeRow="treeRow"
      :tableRow="tableRow"
      @closePage="closePage"
    />
  </both-colum-page>
</template>

<script>
import Detail from './detail'

export default {
  components: {
    Detail,
  },
  data() {
    return {
      // 表格页
      permissionTypeObj: this.$util.listToObj(this.$dict.permissionType()),
      yesOrNoObj: this.$util.listToObj(this.$dict.yesOrNo()),
      treeRow: null,
      tableRow: null,
      content: [],
      defaultExpandedKeys: [1],
      columns: [
        // lang:名称枚举
        {
          label: this.$t('functionManage.permissionEnum'),
          prop: 'permissionEnum',
        },
        // lang:功能类型
        {
          label: this.$t('functionManage.permissionType'),
          prop: 'permissionType',
        },
        // lang:放行接口
        { label: this.$t('functionManage.apiSet'), prop: 'apiSet' },
        // lang:方法名
        {
          label: this.$t('functionManage.method'),
          prop: 'method',
        },
        // lang:页面路径
        { label: this.$t('functionManage.url'), prop: 'url' },
        // lang:图标
        { label: this.$t('functionManage.icon'), prop: 'icon' },
        // lang:是否隐藏右上角权限按钮
        {
          label: this.$t('functionManage.shouldBeBasic'),
          prop: 'shouldBeBasic',
        },
        // lang:排序
        { label: this.$t('base.sort'), prop: 'sort', width: '120px' },
      ],
      // 表单页
      showDetail: false,
    }
  },
  watch: {
    // 当树选中数据发生变化时，更新表格数据
    treeRow(row) {
      if (!row || !row.children) {
        this.content = []
        return
      }
      this.content = row.children
    },
  },
  methods: {
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.defaultExpandedKeys = [this.treeRow.id]
        this.$refs.baseTree.searchList()
      }
    },
    // 获取放行接口显示
    getApiSet(apiSet) {
      if (!apiSet || !apiSet.length) return
      return apiSet.map((item) => item.requestMapping).join(',')
    },
    // 排序
    permissionUpdateSort(currentIndex, type) {
      const targetIndex = type === 'up' ? currentIndex - 1 : currentIndex + 1
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.permissionUpdateSort,
        data: {
          one: this.content[currentIndex].id,
          two: this.content[targetIndex].id,
        },
      }).then(() => {
        // lang:排序成功
        this.$element.showMsg(this.$t('base.sortSuccess'), 'success')
        this.closePage('sort')
      })
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      switch (method) {
        case 'add':
          if (!this.treeRow) {
            this.$element.showMsg(this.$t('base.selectTreeNode'), 'warning')
            return
          }
          if (this.treeRow.permissionType === 'BUTTON') {
            this.$element.showMsg(
              this.$t('functionManage.addButtonError'),
              'warning'
            )
            return
          }
          this.showDetail = true
          break
        case 'edit':
        case 'view':
        case 'del':
          if (!this.tableRow) {
            this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
            return
          }
          if (method === 'del') {
            this.deleteMethod()
          } else {
            this.showDetail = true
          }
          break
        default: // do something
      }
    },
    // 删除
    deleteMethod() {
      const tableRow = this.$util.copyData(this.tableRow)
      tableRow.subjectType = 'PC_MANAGE'
      this.$element
        .showConfirm({
          content: this.$t('base.deleteConfirm'),
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.permissionDelete,
            data: tableRow,
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
            this.closePage('delete')
          })
        })
    },
  },
}
</script>

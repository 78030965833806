<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    width="600px"
    @cancel="cancel"
    @confirm="confirm"
  />
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
    // 当前选中的树节点
    treeRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    const cmd = this.$store.state.cmd
    return {
      cmd,
      formData: {
        shouldBeBasic: 'NO', // 是否是基本的权限
        subjectType: 'PC_MANAGE', // 主体类型
        notes: '',
      },
      apiSetJson: [],
      config: [
        // lang:名称枚举
        {
          tag: 'el-select',
          label: this.$t('functionManage.permissionEnum'),
          prop: 'permissionEnum',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
          },
        },
        // lang:类型
        {
          tag: 'el-select',
          label: this.$t('functionManage.permissionType'),
          prop: 'permissionType',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.permissionType(),
          },
          tagEvents: {
            change(permissionType) {
              if (permissionType === 'BUTTON') {
                self.config[6].hidden = false
              } else {
                self.config[6].hidden = true
              }
            },
          },
        },
        // lang:放行接口
        {
          tag: 'el-cascader',
          label: this.$t('functionManage.apiSet'),
          prop: 'apiSet',
          tagProps: {
            options: [],
            showAllLevels: false,
            props: {
              multiple: true,
              label: 'apiDescription',
              value: 'api',
            },
          },
        },
        // lang:方法名
        { label: this.$t('functionManage.method'), prop: 'method' },
        // lang:页面路径
        { label: this.$t('functionManage.url'), prop: 'url' },
        // lang:图标
        { label: this.$t('functionManage.icon'), prop: 'icon' },
        // 是否隐藏右上角权限按钮
        {
          tag: 'el-select',
          label: this.$t('functionManage.shouldBeBasic'),
          prop: 'shouldBeBasic',
          hidden:
            cmd === 'add' ||
            (this.cmd !== 'add' && this.tableRow.permissionType !== 'BUTTON'),
          tagProps: {
            options: this.$dict.yesOrNo(),
          },
        },
      ],
    }
  },
  mounted() {
    this.setDefaultValue()
    this.apiFindTree()
    this.getPermissionEnum()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          console.log(this.formData, this.tableRow)
          // 处理放行接口
          this.formData.apiSet = this.formData.apiSet.map((item) => [
            item.parent.api,
            item.api,
          ])
        }
      })
    },
    // 查询api接口
    apiFindTree() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.apiFindTree,
      }).then((data) => {
        this.config[2].tagProps.options = data[0].children || []
        this.apiSetJson = data[0].children
      })
    },
    // 查询功能枚举
    getPermissionEnum() {
      this.$ajax({
        url:'/' + this.$util.getLocalStorage('apiprefix') +  this.$api.getPermissionEnum,
      }).then((data) => {
        this.config[0].tagProps.options = data.map((item) => ({
          label: this.$t(`functionEnum.${item.key}`) + '/' + item.key,
          value: item.key,
        }))
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      // 处理放行接口
      const apiSet = formData.apiSet.map((item) => item.pop())
      const newApiSetJson = []
      this.apiSetJson.forEach((item) => {
        if (item.children && item.children.length) {
          item.children.forEach((list) => {
            if (apiSet.indexOf(list.api) >= 0) newApiSetJson.push(list)
          })
        }
      })
      formData.apiSetJson = JSON.stringify(newApiSetJson)
      delete formData.apiSet
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      formData.lft = this.treeRow.rgt
      formData.rgt = formData.lft + 1
      formData.sort = this.treeRow.children
        ? this.treeRow.children[this.treeRow.children.length - 1].sort + 1
        : 0
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.permissionAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      Object.assign(tableRow, formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.permissionUpdate,
        data: tableRow,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
  },
}
</script>